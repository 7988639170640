import Breadcrumbs from "../breadcrumbs"
import Card_news from "../card_news";
import { getFeatured } from "../../functions/functions_novelties";
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import { useEffect, useState } from 'react'
import {useParams } from "react-router-dom";
import { getDateTime } from "../../functions/functions_novelties";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import { get_short_desc } from "../../functions/functions_dev";
// import DateTimeInput from '../../../Globals/DateTimeInput'

const Main = () => {

    const novelty = useSelector(state => state.novelties.novelty)
    const loading = useSelector(state => state.novelties.loading)
    const dispatch = useDispatch()
    const {id} = useParams(); 

    // const { data } = useSelector((store) => store.developments.development);
    const settings = useSelector(store => store.settings)
    const [listActives,setListActive] = useState([])

    const data = useSelector(state => state.novelties.novelties)
    
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [content, setContent] = useState('');

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

    useEffect(() => {
        if(novelty){
          if(novelty.content){
            const blocksFromHtml = htmlToDraft(novelty.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState))
            
        }
        window.scrollTo(0, 0);
        }
      },[novelty])

    return novelty ?  (
        <main class="main ">
            <div class="container-fluid-85 row">
                <div className="primer_bloque col-12 col-lg-7">
                    <div className="div_breadcrums mt-5 mb-5 ms-5">
                        <Breadcrumbs   
                        props={[
                            { name: "Inicio", route: settings.base_url, location: "" },
                            { name: "Novedades", route:  settings.base_url + "novedades", location: "" },
                            {
                                name: get_short_desc(novelty?.title,30),
                                route:'',
                                active:true
                            }
                        ]}
                        />
                    </div>
                    <div className="div_novedad">
                        {/* <p className="texto_lateral fs-6 text-regular" style={{left: '-7rem'}}>Nuestras Novedades</p> */}
                        <span className="fecha fs-6 text-regular">
                            {novelty.date_published ? getDateTime(novelty.date_published, true) : 'Cargando..'}
                        </span>
                        { novelty?.image 
                            ? <div className="banner-container mt-4"> 
                                <img className="banner-image" src={novelty.image} alt={novelty.title} />
                                <div className="banner-caption">{novelty.footer}</div>
                              </div> : null }
                        <h2 className="titulo fs-2 text-semibold mt-3">{novelty.title}</h2>
                        <h3 className="subtitulo fs-4 text-semibold mt-4">{novelty.headline}</h3>
                        <h4 className="publicado_por fs-7 mt-5">Publicado por 
                            { novelty?.creator 
                                ? ` ${novelty?.creator?.first_name} ${novelty?.creator?.last_name}`
                                : ' Anonimo' }.</h4>
                        <p className="texto_novedad fs-7 text-regular mt-5">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                // onEditorStateChange={this.onEditorStateChange}
                                disabled={true}
                            />
                        </p>
                        <hr className="hr_novedad mt-5 mb-5" />
                        <div className="pills">
                            { novelty.tags ? 
                                novelty.tags.map((element, index) => (
                                    <span className="pill" key={index}>{element.name}</span>
                                )) : null }
                        </div>
                        <a className="button-common text-bold" href="/novedades">VOLVER A NOVEDADES</a>
                    </div>
                </div>
                <div className="segundo_bloque col-12 col-lg-5 ">
                    <div className="container-fluid position-relative">
                        <h2 className="titulo text-regular">Novedades destacadas</h2>
                        <div className="div_novedades_destacadas mt-5">
                            <div className="row">
                                { data ? 
                                    (getFeatured(data).map((element, index) => (
                                        <div className="col-12 mt-5 mb-5" key={index}>
                                            <Card_news novelty={element} />
                                        </div> 
                                    ))) : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    ):
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Main