import { useLocation } from 'react-router-dom';
import React from 'react';
import { informCustomEvent } from '../../../../functions/functions_analytics';

const FlapBtn = ({alignment="left", linkTo, linkTxt, showOn, type, section, small_type, small_section, name}) => {
    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')

    const checkPage = () => {
        if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
        if(pathname.toLowerCase().slice(0,6).includes('unidad') && showOn.toLowerCase() === 'ficha de la propiedad'){return true}
        if(pathname.toLowerCase().slice(0,10).includes('desarrollo') && showOn.toLowerCase() === 'ficha de emprendimiento'){return true}
        if(pathname.toLowerCase() === 'listos-para-vivir' && showOn.toLowerCase() === 'emprendimientos'){return true}
        if(pathname.toLowerCase() === 'en-desarrollo' && showOn.toLowerCase() === 'emprendimientos'){return true}
        return (Array.isArray(showOn)) 
            ?  showOn.toLowerCase().includes(pathname.toLowerCase())
            : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
    }
    return checkPage() && (
        <div id="flap-btn" className={`fixed-btn-wrapper ${alignment}`}>
            <a href={linkTo} className="btn-link" target="_blank" 
               onClick={() => informCustomEvent("PUSH_" + small_section + "_" + small_type + "_" + name)}>
                {linkTxt}
            </a>
        </div>
    )
}

export default FlapBtn
