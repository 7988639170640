import { useSelector,useDispatch } from "react-redux";
import React , { useState, useEffect} from 'react'
import { updateFields,formTokko } from '../../redux/contactDucks'
import {get_shortDescription, getGroup_CustomTag} from '../../functions/functions_dev'
import BreadCrumbs from "../breadcrumbs";
import imgLogo from "../../assets/images/quartier.png"

const Data = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);


    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        empresa:'',
        phone:'',
        text:'',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }
    const sendForm = (event) => {
      event.preventDefault()
      // dispatch(formTokko('Contacto',null))
  }
  const direccion = (status) => {
    if (status === 6) {
      return "listos-para-vivir"
    }else {
      return "en-desarrollo"
    }
  } 
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])
  
    const getLastFile = (development) => {
      return development.files[development.files.length - 1];
    }


  // Render
  return data ? (
    <section class={"section "} >
       <div class="container-fluid pt-0" id="proyectos">
          <div className="div_breadcrumbs">
            <BreadCrumbs
              props={[
                { name: "Inicio", route: settings.base_url, location: "" },
                { name: data?.construction_status === 6 ? "FINALIZADOS" : "EN DESARROLLO" , route: settings.base_url + direccion(data.construction_status), location: "" },
                {
                  name: data.name,
                  route:'',
                  active:true
                }
              ]}
            />
          </div>

          <div className="row div_descripcion">
            <div className="col-lg-4  col-12 first_div mb-5 mb-lg-0">
              <h3 className="fs-9 text-bold text-uppercase">{get_shortDescription(data.description).description}</h3>
            </div>
            <div className="col-lg-4 col-12 second_div d-flex flex-column">
              <h3 className="text-semibold fs-6">CARACTERÍSTICAS:  </h3>
              <div className="iconos_descripcion mt-3">
                <div className="div_icon d-flex">
                  <i className="icon-check-solid"></i>
                  <span className="fs-9 font-0 text-regular">Ubicación</span>
                </div>
                <div className="div_icon d-flex">
                  <i className="icon-check-solid"></i>
                  <span className="fs-9 font-0 text-regular">Amenities</span>
                </div>
                <div className="div_icon d-flex">
                  <i className="icon-check-solid"></i>
                  <span className="fs-9 font-0 text-regular">Oportunidad</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 tertiary_div d-flex flex-column">
              <img src={imgLogo} alt="" />
              {console.log(data)}
              {/* <a href={getLastFile(data)?.file} target="_blank" className="mt-auto button-common d-flex"> <div><i className="icon-arrow"></i></div> DESCARGAR BROCHURE</a> */}
              {data.files.length > 0 &&
                  <a href={getLastFile(data)?.file} target="_blank" className="mt-auto button-common d-flex"> <div><i className="icon-arrow"></i></div> DESCARGAR BROCHURE</a>
              }
            </div>
          </div>
        </div>
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;
