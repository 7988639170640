import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTextShort, getDateTime } from "../functions/functions_novelties";
import { getNoveltieAction, clearNoveltyAction } from "../redux/noveltiesDucks";

const Card_news = (props) => {
    
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);
    const { novelty } = props;
    const tags_count = props.tags_count ? props.tags_count : 999;
    // Home
    const isHome = Boolean(useLocation().pathname === '/') && useLocation().pathname.length === 1;
    // Novedades
    const isNovedades = useLocation().pathname === '/novedades'
                     && useLocation().pathname.length === 10;
    // Novedad
    const isNovedad = useLocation().pathname.includes('/novedades/') 
                     && useLocation().pathname.split('/')[1].length === 9;

    return (
        <div className="div_card_new me-1 position-relative">
            <img className="position-absolute img_card" src={novelty.image} alt={novelty.image} />
            <div className="card_new p-4"> 
                <div className="content_card">
                    <div className="content_body">
                        <span className="card_date fs-8 font-0 text-regular">{getDateTime(novelty.date_published)}</span>
                        <h3 className="mt-4 fs-3 font-0 text-regular text-left">{getTextShort(novelty.title,50)}</h3>
                        <p className="fs-6 font-0 text-regular mt-4 mb-4 text-left">{getTextShort(novelty.headline,80)}</p>
                    </div>
                    { (!isHome && !isNovedad) ?     
                        <div className="div_pill d-flex mb-4 flex-wrap">
                            { novelty.tags.map((item, index) => (
                                index < tags_count 
                                    ? <span className="pill me-2 mb-2" key={index}>{item.name}</span> 
                                    : null ))}
                        </div> : null }
                    <Link 
                        onClick={() => dispatch(clearNoveltyAction()) 
                                     + dispatch(getNoveltieAction(novelty.id))} 
                        to={settings.base_url + "novedades/" + novelty.id + "/" + novelty.slug}
                        className={`see_more d-inline-flex ${isNovedades ? 'fs-11' : 'fs-13'}`}>
                            VER NOVEDAD <span className="text-hidden"> COMPLETA</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Card_news;