import { useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../breadcrumbs"
import Map from '../map'

const Main = () => { 
    const settings = useSelector(store => store.settings)
    return (
        <main id="contacto" class="main_contact text-center" >
            <h2 className="title-ubicacion font-0 text-bold">
                ¿Querés <span>visitarnos?</span>
            </h2>
            <h3 className="subtitle-ubicacion font-0 text-semibold text-uppercase">
                Comodoro Pedro Zanni 351, CABA.
            </h3>
            <div className="div_map_contact">
                <Map coordenadas={{ geo_lat: -34.5834841, geo_long:-58.3715513 }} />
            </div>
        </main>
    )
}

export default Main