import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFiltersNoveltiesAction } from "../../redux/noveltiesDucks";
import Card_news from "../card_news";

const Blogs = () => {
    
    const dispatch = useDispatch()
    const data  = useSelector((store) => store.novelties.grid_novelties);
    
    useEffect(() => {
        dispatch(getFiltersNoveltiesAction())
    }, [])

    return (
        <div className="container-fluid mb-5 pb-5">
            <section className="div_blog position-relative d-block">
                <div className="container">
                    <div className="div_blog__title  d-none d-lg-flex mb-5">
                        <h2 className="font-0 fs-1 text-regular ">Conocé las últimas novedades</h2>
                    </div>
                    <div className="div_blog_container">
                        <div className="row div_cards-news">
                            { data ? 
                                data.length > 0
                                    ? data.sort((a, b) => b.position - a.position).map((item, index) => (
                                        item.is_active 
                                          ? <div className=" mb-5 col-12" key={index}>
                                                <Card_news novelty={item} />
                                            </div> : null
                                    )) :
                                    <h4 className="text-white text-center my-5" style={{fontSize:"2rem"}}>
                                        No existen novedades relacionadas con el filtro de búsqueda seleccionado.
                                    </h4>
                                :  <>
                                        <div class="loader relative">
                                            <div class="page-lines">
                                                <div class="container">
                                                <div class="col-line col-xs-4">
                                                    <div class="line"></div>
                                                </div>
                                                <div class="col-line col-xs-4">
                                                    <div class="line"></div>
                                                </div>
                                                <div class="col-line col-xs-4">
                                                    <div class="line"></div>
                                                    <div class="line"></div>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="loader-brand">
                                                <div class="sk-folding-cube">
                                                <div class="sk-cube1 sk-cube"></div>
                                                <div class="sk-cube2 sk-cube"></div>
                                                <div class="sk-cube4 sk-cube"></div>
                                                <div class="sk-cube3 sk-cube"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
      )
}

export default Blogs