import React, {useRef,useEffect} from "react";
import {useSelector} from 'react-redux'
import {get_cover, get_shortDescription } from "../../functions/functions_dev"
import { Link } from 'react-router-dom'
import { Header } from './Header' 
import OwlCarousel from 'react-owl-carousel3';

const Main = () =>{
    const {data} = useSelector(store => store.developments.development)
    
    const slider = useRef()
    

    const getDescription = (element) => {
        if(element.id ===39383){
            return element.description.split(".")[0] + ".";
        }
        else{
            return get_shortDescription(element.description).title
        }
    }

    const scrollTo = (section) => {
        var rec = document.getElementById(section).getBoundingClientRect();
        return window.scroll({left:0, top:rec.top + window.scrollY - 0, behavior: 'smooth'});
      }

  
    useEffect(() => {
      window.scrollTo(0, 0);
    });

    return data ? (
        <>
            <Header />
            <main class="main">
                <OwlCarousel
                    loop={false}
                    // dots={true}
                    autoplay={true}
                    center={true}
                    items={1}
                    margin={0} 
                    rewind={true}
                    drag={false}
                    ref={slider}>
                    {data.photos.map((element,index) => (
                        element.is_front_cover && (
                            <div className="">
                            <img src={(element.original)} alt="" />
                            <div className="opacity"></div>
                            </div>
                            )
                    ))}
                </OwlCarousel>
                <div className="art">
                    {console.log(data)}
                    <div className="container-fluid ">
                        <div className="row d-flex justify-content-center align-items-center">
                            {/* <h2>{data.name}</h2>
                            <h4 className="sspro-regular">{data.publication_title}</h4>
                            <p>{getDescription(data)}</p> */}
                            <div className="text-content text-center text-lg-end d-flex flex-column align-items-end">
                                <h2 className="col-12 text-bold">{data.name}</h2>

                                {/* <div className="col-12 div_bottom_arrow d-flex d-lg-block justify-content-center justify-content-lg-start">
                                    <a onClick={(e) => scrollTo("proyectos") + e.preventDefault()}>
                                        <span className="icon-arrow-solid"></span>
                                    </a>
                                </div> */}
                                <div className="description_main col-12 col-lg-7">
                                    {/* <h4 className="font-1 text-regular">{get_shortDescription(data.description).title}</h4> */}
                                    <h4 className="font-1 text-regular">{data.publication_title}</h4>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* <div className="arrow__container d-flex justify-content-between">
                    <div onClick={() => (slider.current.prev())} className="icon-arrow_left arrow_left"></div>
                    <div onClick={() => (slider.current.next())} className="icon-arrow_right arrow_right"></div>
                </div>
                <div className="arrow-left"></div>
                <div className="arrow-right"></div> */}
            </main>
        </>
    ):
    <>
        
    </>
}

export default Main