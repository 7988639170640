import React , {useEffect} from 'react'

// -- Componentes -- //

import Main from '../components/novedad/main'
import { FirstSection } from '../components/novedad/FirstSection.jsx'
import { Header } from '../components/header/Header';
import { Sidebar } from '../components/sidebar/Sidebar';
import { SectionForm } from '../components/section-form/SectionForm'
import { Whatsapp } from '../components/Whatsapp'
import {useParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'

import {getNoveltieAction,getNoveltiesAction,clearNoveltyAction} from '../redux/noveltiesDucks'


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Novedad(){

    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(() => {
        dispatch(clearNoveltyAction())
        dispatch(getNoveltieAction(id));
        dispatch(getNoveltiesAction(true))
      }, []);

    ScrollToTopOnMount()
    return(
        <div className="novedad">
            <Header />
            <Sidebar/>
            <Whatsapp />
            <FirstSection  />
            <div className="layout">
                <Main />
                <div className="content">
                <SectionForm />
                </div>
            </div>
        </div>
    )
}

export default Novedad
