import React from 'react'
import { FirstSection } from '../../emprendimientos/FirstSection';
import { Header } from '../../header/Header';
import { Whatsapp } from '../../Whatsapp'

export const Main = ({status}) => {
  return (
    <>
      <Header />
      <Whatsapp />
      <FirstSection status={status} />
    </>
  )
}
