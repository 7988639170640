import React , {useEffect} from 'react'
import { useDispatch } from 'react-redux'
// -- Componentes -- //
import {Main} from '../components/pages/nosotros/main/Main'
import { Nosotros } from '../components/pages/nosotros/Nosotros'
import { getActionsAction } from '../redux/actionsDucks'
import { getDevelopmentsAction } from '../redux/developmentsDucks'
import { Sidebar } from '../components/sidebar/Sidebar'

function ScrollToTopOnMount() {
    const dispatch = useDispatch();
    useEffect(() => {
      window.scrollTo(0, 0);
      dispatch(getActionsAction())
      dispatch(getDevelopmentsAction())
    }, []);
      return null;
}

function PageNosotros(){

    ScrollToTopOnMount()
    return(
     
      <div className="nosotros">
        <div className="layout d-flex" style={{ width: '100vw' }}>
          <Sidebar />
          <Main />
        </div>
        <div className="content">
          <Nosotros />
        </div>
       
      </div>
        
      
    )
}

export default PageNosotros;
