import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../redux/noveltiesDucks";
import Breadcrumbs from "../breadcrumbs"

const Main = () => {

    const dispatch = useDispatch();
    const settings = useSelector(store => store.settings)
    const { tags } = useSelector(store => store.novelties)
    const [listActives,setListActive] = useState([])

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

    // Update filters in redux
    useEffect(() => {
        // if(activeTags.length > 0){
        dispatch(updateFiltersNovelties(listActives));
        // }
    }, [listActives])

    useEffect(() => {
        dispatch(clearNoveltyAction());
    }, [])

    return (
        <main id="novedades" class="main">
            <div class="container-fluid">
                <div className="div_breadcrums">
                    <Breadcrumbs   
                    props={[
                        { name: "Inicio", route: settings.base_url, location: "" },
                        { name: "Novedades", route:  settings.base_url + "novedaades", location: "", active:true },
                    ]}
                    />
                </div>
               <div className="container">
                    <div className="row hilera">
                        <div className="div_selected">
                            <div className="div_search d-flex justify-content-between">
                                <div className="row div_first_search align-items-center">
                                    <div className="col-12 d-flex align-items-center">
                                    <i className="icon-label-solid fs-3 me-lg-5 me-3 mt-2 mt-lg-0"></i>
                                        <div className="d-flex justify-content-lg-start justify-content-center text-center text-lg-start flex-wrap">
                                            { listActives.map((element, index) =>(
                                                <div className="pill my-1 wow fadeInRight fs-7 d-block" key={index}>
                                                    { tags.find(item => item.id === element).name }
                                                    <button onClick={() => toggleTag(element)} className="ms-2">
                                                        <i className="icon-close-light fs-12"></i>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="div_second_search d-flex align-items-center">
                                    <span className="fs-5 text-regular me-3 d-none d-lg-inline-block cursor-pointer" onClick={() => setListActive([])}>Limpiar</span>
                                    <button onClick={() => dispatch(getFiltersNoveltiesAction(listActives))}  className="fs-6 buttonGhostTwo text-uppercase align-items-center justify-content-start d-flex w-auto"> 
                                        <div className="d-none d-lg-inline-block"> Buscar </div> 
                                        <i className="icon-search-solid"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap mt-4">
                        { tags.map((element,index) => (
                            listActives.find(e => e === element.id) ? '' :
                            <div className="pill cursor-pointer fs-7 mb-3 mb-lg-0" key={index} >
                                <div onClick={() => toggleTag(element.id)} className="">{element.name}</div>
                            </div>
                        ))}
                    </div>
               </div>
            </div>
        </main>
    )
}

export default Main