import Card from "./../property-card";
import { useSelector, useDispatch } from "react-redux";
import { getAdd_PropertiesAction, redux_updateOrders } from "../../redux/propertiesDucks";
import { useEffect,useState } from "react";
import { useHistory } from "react-router";
import { useRef } from "react";


const Results = () => {
  const dispatch = useDispatch();
  const listed = useSelector((store) => store.properties.listed);
  const properties = useSelector((store) => store.properties.properties);
  const theme = useSelector((store) => store.theme);
  const offset = useSelector((store) => store.properties.offset);
  const router = useHistory()
  const [location, setLocation] = useState(window.location.pathname.split("/"));
  const [orders,setOrders] = useState({order:'desc',order_by:'price'})
  const settings = useSelector(store => store.settings)
  const {data} = useSelector(store => store.properties.properties)

  const selectorOrder = useRef()

  const checkStatusButton = () => {
    if (properties.data) {
      if (
        properties.data.objects.length > 0 &&
        properties.data.objects.length < properties.data.meta.total_count
      ) {
        return true;
      }
      return false;
    }
  };

  function traductorSelect(){
    switch(selectorOrder.current.value){
      case '0': setOrders({order:'desc',order_by:'price'}); break;
      case '1': setOrders({order:'asc',order_by:'price'}); break;
      case '2': setOrders({order:'desc',order_by:'age'}); break;
      case '3': setOrders({order:'asc',order_by:'age'}); break;
      case '4': setOrders({order:'desc',order_by:'roofed_surface'}); break;
      case '5': setOrders({order:'asc',order_by:'roofed_surface'}); break;
    }
  }
  useEffect(() => {
    dispatch(redux_updateOrders(orders))
  },[orders])

  // useEffect(() =>{
  //   functionResizing();
  // }) comentado

  // const functionResizing = () => {
  //   $(document).ready(function() {
  //     $('#resizing_select').change(function(){
  //       $("#width_tmp_option").html($('#resizing_select option:selected').text()); 
  //       $(this).width($("#width_tmp_select").width());  
  //     });
  //   });
  // } comentado

  return (
    <div className="px-0 w-100 ">
      <div className="results ">
          <div className="align-self-end d-flex flex-column align-items-end mb-lg-3" >
              {/* <div>
                <div className="listing-type d-none d-lg-flex pe-3">
                  <div>
                    Ver:
                  </div>
                  <i className={`icon-grid ${!listed ? "active"  : ""}`} onClick={()=>{dispatch({type:"CHANGE_TYPE",payload:false})}}></i>
                  <i className={`icon-lateral ${listed ? "active" : ""}`} onClick={()=>{dispatch({type:"CHANGE_TYPE",payload:true})}}></i>
                </div>
              </div> */}

              <div className="orderby text-white d-flex align-items-end justify-content-end order-mobile text-end">
                <div className="d-lg-inline-block order-text font-1 fs-8 text-white"> Ordenar por: </div>
                <div className="select-wrapper font-1 fs-8">

                  <select ref={selectorOrder} onChange={() => traductorSelect()} name="orderby" id="resizing_select">
                    <option value="0">Mayor precio</option>
                    <option value="1">Menor precio</option>
                    <option value="2">Mayor antigüedad</option>
                    <option value="3">Menor antigüedad</option>
                    <option value="4">Mayor superficie</option>
                    <option value="5">Menor superficie</option>
                  </select>
                  <i className="icon-arrow">   </i>
                
                </div>
                <select id="width_tmp_select">
                  <option id="width_tmp_option"></option>
                </select>
              </div>
            </div>
        <div className="row no-gutters justify-content-between ">
        
          {properties.data ?
           properties.data.objects.map((property, index) =>
              (
                <div className={(!listed ? "col-lg-6" : "col-lg-6") + " px-lg-4 mb-4 mb-lg-5"}>
                <Card property={property} />
              </div>
              )
          ):
          <div className="loading-section"><div className="lds-dual-ring"></div> </div>}
          <div
            className={
              "col-12 text-center mt-5 " +
              (checkStatusButton() ? "d-flex justify-content-center" : "d-none")
            }
          >
            <button
              onClick={() => dispatch(getAdd_PropertiesAction())}
              className="w-auto button-common d-inline-block font-0 text-bold"
            >
              CARGAR MÁS PROPIEDADES
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
