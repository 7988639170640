import {useDispatch,useSelector} from 'react-redux'
import { changeLocationAction } from '../redux/themeDucks';
import { Link } from "react-router-dom";
import { getGroup_CustomTag } from '../functions/functions_dev';

const Breadcrumbs = props =>{
    const dispatch = useDispatch();
    const property = useSelector((store) => store.properties.property).data;
    const {data} = useSelector((store) => store.developments.development);

    return (
        <div className="breadcrumb-div" style={{position:"relative",zIndex:"9"}}>
            <nav aria-label="breadcrumb" className="mb-lg-4">
                <ol className="breadcrumb">
                    {props.props.map((item,index) =>(
                        <li className={'breadcrumb-item text-decoration-none fs-10 font-0 text-semibold text-center' + (index < props.props.length - 1 ? ' ' : '' )} key={index}> { (item.active ?  item.name : <Link className={'text-decoration-none '} onClick={() => dispatch(changeLocationAction(item.location))} to={item.route}>{item.name}</Link>)} </li>
                    ))}
                    {/* <li className="breadcrumb-item text-decoration-none fs-3">{props.name}</li>
                    <li className="breadcrumb-item text-decoration-none fs-3">Emprendimientos</li> */}
                </ol>
            </nav>
        </div>
    )
}

export default Breadcrumbs