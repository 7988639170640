import { data } from 'jquery'
import { useSelector } from 'react-redux';
import Map from '../map'
const Location = () => {
    const {data} = useSelector((store) => store.developments.development);

    return data ? (
        <>
            <section className='div_ubicacion' id="ubicacion">
                <div className='div_header'>
                    <h2 className="font-0 fs-3 text-bold text-uppercase" style={{ letterSpacing: ".2rem" }}>Ubicación</h2>
                    <h5 className="font-0 fs-5 text-semibold" style={{marginTop:"1.5rem"}}>{data.fake_address ? (data.fake_address + ", " + data.location.name + ".") : ''}</h5>
                    <div className="div_news__title  d-flex mb-5">
                        <h2 className="font-0 fs-5 text-regular ">Mapa</h2>
                    </div>
                </div>
                <div className="dev-map">
                    <Map coordenadas={{ geo_lat: data.geo_lat, geo_long: data.geo_long }} />
                </div>
            </section>
        </>
    )
    :
    ''

}

export default Location