// Constantes
const dataInicial = {
    name:'Unité Quartier',
    short_name:'Unité Quartier',
    base_url:'/',
    client_id:29,
    social:
    {
        facebook:'',
        instagram:'',
        twitter:'',
        linkedin:'',
        youtube:'',
    }
    ,
    contact:
    {
        phone:['(011) 4844 0259' , ''],
        whatsapp:{link:'5491137836141',visual:'(011) 3783 6141'},
        mail:'hello@unitequartier.com'
    },
    location:
    {
        address:'',
        region:'',
        cp:''
    },
    keys:{
        // captcha:'6Lel-rodAAAAAH3vs9192XBf4sd_bSWNAjtqUX53', 
        captcha:'6LepkUQhAAAAAK3Lyr0qrZBH2aWFjGEQldccBJMC',  // Unite quartier
        // tokko:'4aeb6e6d5e8f5f041734390ce1a2a65238328ae9', // Cambiar por tokko de construfact
        // tokko:'0416f2bc7f7b671af94f22e769040177ce0ea879', // Cambiar por tokko de construfact
        // tokko:'2e4da1b9a9b3c724ab1cbfa7c1f92ab291ba1a2b', // FARIAS
        tokko:'e73bac8c5a6981ea579c0e2e61ccf9f01b353c7d', // unite-quartier
        google_tgm:'',
        mediacore: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODMyZGJlYjQ2YjA3Yzk0OWFmYjFlZjcyMjdmOGUyMGUwZDU4NWRlMTQwMjRiZjYwNDI3ODZjMDc4MGYwZDdiZTNlMjNiOGYxNDMxMmMwZWUiLCJpYXQiOjE2MzUxODA1MTMsIm5iZiI6MTYzNTE4MDUxMywiZXhwIjoxNjY2NzE2NTEzLCJzdWIiOiIyOSIsInNjb3BlcyI6W119.G7y15Pvy53Y3USzw6BRTh38GmzR2YchXBum0WRwzbsy68TIDhIQ5IulSEG5RBUAd3yddBn6K4sb4fZfvqkfdKuBZua1dxzpC8ozNbfuPFtt8HR52Qy9avCdVY-MgyFukY2u7FmuMdBycLt2qQLfjrKNJtBfeS7DxDwmZunOK5z3QIXc3ekjfLhJaJpEntPT4C9NzfMpjkGkdmsxBXYV7M9jYgBDiB4UiMw85OcmqP_u7F2mqw9WMnD7r-tyusc2_sQxT8B_lzuVs7gP5Se1HindRBuC2kSOYTLguH0ymBfNeS7p6eRbWN8HkkwGrdWcvNvklq9U58C9kzBaiTS9W3gBjuuww3r4dOVYfDuHYvBdgkFy1CXrJmPYGZawyPaGhRLsE6UDBRmdbcuGu6ILjfvT0hJ-zkkBniRysiIPITA0COCeXjg1XdD70euuaVpv9RT0EU8LzM2G__i_Z-SX_Beg_40nrGXfMYgdgnRnAgpcYFaeK2VZBmsL9XWsek8MP_tLvRhOQSTkFMDEc6uviN6YRjzcPWm8GMAte-Bi9vv31C8lFosjzD07lUj-8jWAZqGdm23yquDfth9Njt2e4UNrSvxU5sJXrjpKeHy8RYs3OE-s6cMwQfNnmxVcc6o4S0TZEHfqFOYCewotvjFm1Ea47b-7oKhT-jbVo_2aXDVg",
        google_map:'AIzaSyCbcrlNkJDwLi5QoG446Zie7o5vyJukvC0', // Cambiar por google_map de unite-quartier
        ga_analytics_mediacore:'G-37T1R5P56S'
    },
    seo:{
        title:'Unité Quartier',
        description:'',
        url:'',
        img:'',
        image_url:'',
        site_name:'',
        locale:'',
    }
}
export default function settingsReducer(state = dataInicial, action){
    return state
}