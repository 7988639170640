import Filter from './filter'
import Properties from './result-properties'

const Grid = () =>{
    return(
        <div className="container-fluid separate-cards">
            
                {/* <Filter></Filter> */}
                <Properties></Properties>
           
        </div>
    )
}

export default Grid