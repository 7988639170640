import React from 'react';
import { SectionForm } from '../../section-form/SectionForm';
import Trayectoria from '../../../components/nosotros/trayectoria';
import Experiencia from '../../../components/nosotros/experiencia';
import Equipo from '../../nosotros/equipo';
import Contacto from '../../contacto/main';


export const Nosotros = () => {
  return (
    <>
      <Trayectoria />
      <Experiencia />
      <Contacto/>
      <SectionForm />
    </>
  )
}
