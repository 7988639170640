import React, { useState, useEffect }  from 'react';
import { useLocation } from 'react-router-dom'
import Countdown from 'react-countdown';
import { informCustomEvent } from '../../../../functions/functions_analytics';

const TopAlert = ({linkTo, linkTxt, textAlert, showOn, eventDate, utmValue, small_type, small_section, name}) => {

    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','');
    const [closed, setClosed] = useState(false);

    // Conditional toggle class on page to show and state, that can redirect
    useEffect(() => {
        if(checkPage()) {
            setTimeout(() => {
                document.getElementById("top-alert")
                    ?.classList.toggle("animate-show")
            }, 1000)
        }
    // Toggle class when [closed] state change, that can redirect
    }, [pathname, showOn, closed])

    // Check where shows
    const checkPage = () => {
        if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
        if(pathname.toLowerCase().slice(0,6).includes('unidad') && showOn.toLowerCase() === 'ficha de la propiedad'){return true}
        if(pathname.toLowerCase().slice(0,10).includes('desarrollo') && showOn.toLowerCase() === 'ficha de emprendimiento'){return true}
        if(pathname.toLowerCase() === 'listos-para-vivir' && showOn.toLowerCase() === 'emprendimientos'){return true}
        if(pathname.toLowerCase() === 'en-desarrollo' && showOn.toLowerCase() === 'emprendimientos'){return true}
        return (Array.isArray(showOn)) 
            ?  showOn.toLowerCase().includes(pathname.toLowerCase())
            : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
    }

    // Render countdown
    const rendererCtd = ({ days, hours, minutes, seconds }) =>
        <div className="counter d-flex">
            <span className="mx-2">
                <span className="date-val">{days}</span> DÍAS</span>
            <span className="me-2">
                <span className="date-val">{hours}</span> HS</span>
            <span className="me-2">
                <span className="date-val">{minutes}</span> MIN</span>
            <span className="me-2">
                <span className="date-val">{seconds}</span> SEG</span>
        </div>

    const capitalizeFirst = (text) =>
        text[0].toUpperCase() + text.slice(1, text.length)

    return checkPage() ? (
        <div id={"top-alert"} className={`alert alert-dismissible show ${eventDate ? 'counter-wrapper-size' : ''}`} role="alert">
            <div className="container-fluid">
                {/* Counter */}
                { eventDate &&
                    <div className="counter-wrapper">
                        Quedan 
                        <Countdown 
                            date={new Date(eventDate).getTime()} 
                            renderer={rendererCtd}/>
                    </div> }
                {/* Text */}
                { textAlert && 
                    <div className={`text-content my-2`}>
                        { (eventDate) ? ` ${textAlert}` : capitalizeFirst(textAlert) }
                    </div> }
                {/* Link */}
                { (linkTo && linkTxt) &&
                    <a href={linkTo + utmValue} className="btn-link" target="_blank"
                        onClick={() => informCustomEvent("PUSH_" + small_section + "_" + small_type + "_" + name)}>
                        {linkTxt}
                    </a> }
            </div>
            {/* X close button */}
            {/* Removed [data-bs-dismiss='alert'] because can't redirect */}
            <button className="btn btn-link btnClose" type="button" role="button" 
                id="closeAlert" aria-label="Close" onClick={() => setClosed(!closed)}>
                &times;
            </button>
        </div>
    ) : null
}

export default TopAlert

// Component usage
// <AlertTop
//    linkTo={action.texts.text_URL}
//    name={action.name}
//    small_type={action.small_type}
//    small_section={action.small_section}
//    linkTxt={action.texts.text_button}
//    textAlert={action.texts?.text}
//    showOn={action.section}
//    utmValue={action.texts.text_utm}
//    eventDate={action.date_start} />