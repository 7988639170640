import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getThree } from "../../functions/functions_novelties";
import { getNoveltiesAction } from "../../redux/noveltiesDucks";
import Card_news from "../card_news";
import img1 from '../../assets/images/quartier.png'

function News() {

  const dispatch = useDispatch();
  const novelties = useSelector(store => store.novelties.novelties)

    useEffect(() => {
        dispatch(getNoveltiesAction(true))
    }, [])

  return novelties?.length > 0 && (
    <>
        <section className="div_news position-relative">
            <div className="container-fluid ">
                <div className="row m-auto justify-content-start">
                    <div className="col-12 text-center mb-5">
                        <img src={img1} alt="" />
                        <h2 className="font-0 text-regular mt-4 fs-3">NOVEDADES</h2>
                    </div>
                    { novelties ? 
                        getThree(novelties).map((item, index) => (
                            <div key={index} className="col-12 col-md-12 col-lg-4 col-xl-4 mb-4">
                                <Card_news tags_count={2} novelty={item} />
                            </div>
                    )) : null }
                    <div className="col-12 text-center div_btn">
                        <a className="button-common text-bold" href="/novedades">VER TODAS<span className="hide-text"> LAS NOVEDADES</span></a>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default News;
