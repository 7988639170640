import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from "react-router-dom";
import {getGroup_CustomTag} from '../../functions/functions_dev'
import {getNoveltiesAction} from '../../redux/noveltiesDucks'
import { useDispatch } from 'react-redux';
import { updateFiltersDevelopment } from '../../redux/developmentsDucks';
import logoquartier from '../../assets/images/logoQuartier.png'


 

export const Navbar = () => {

  const dispatch = useDispatch()

  const settings = useSelector((store) => store.settings);
  const {data} = useSelector((store) => store.developments.development);
  const filters = useSelector((store) => store.properties.filters);
  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  const unidad = useSelector((store) => store.properties.property).data;

  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const view = curLocation ? curLocation.split("/")[0] : ''
  const [stickyClass, setStickyClass] = useState('');

  const checkActive = (_pathname) => {
    if((_pathname === 'ventas' || _pathname === "alquileres") && pathname.includes('venta') || pathname.includes('alquiler')){
      if (filters.operation){
        if(filters?.operation?.includes(_pathname)){
            return true;
        }
      }
    }
    else{
      if(pathname === (_pathname)){
          return true;
      }
    }
    return false
  }

  // nav estatica
  
  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 190 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };

  // nav estatica

  const staticBody = () => {
    var body = document.body;
    body.classList.toggle("overflow-hidden-mobile");
  }

  useEffect(() => {
  },[curLocation])

  const novelties = useSelector((store) => store.novelties.novelties);

  useEffect(() => {
    if(!novelties){
      dispatch(getNoveltiesAction(true))
    }
  },[])

  return  (
    <div className={`w-100 navbar justify-content-end py-5 d-lg-flex col-12 m-auto d-none ${stickyClass}`}>
      {/* <Link to="/" className={`brand ${stickyClass}`}>
        <img className="brand__image" src={ logoquartier } alt="foto" />
      </Link> */}
      <div className="container-fluid">
      <nav className="col-12 d-flex justify-content-end m-auto">
          <Link>
            {/* <img  id="d-only" style={{maxWidth:"15rem"}} className={view === "desarrollo" ? 'd-block' : "d-none"} height="70" src={settings.base_url + "img/logos/"+ ( data ? getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '') +".svg"} alt="" /> */}
            {/* <img id="d-only"  className={view === "unidad" ? 'd-block' : "d-none"} height="70" src={settings.base_url + "img/logos/"+ ( unidad ? getGroup_CustomTag(unidad.development.custom_tags,"Emprendimientos").name.toLowerCase() : '') +".svg"} alt="" /> */}
          </Link>
          <ul className="nav">
            {
              view !== "desarrollo" && view !== "unidad" && view!== "modulo" ? ( // Validador de si es o no una vista
                <>
                  <li onClick={() => staticBody()} className={"nav-item " + (curLocation === '' ? "active" : '')}>
                    <Link className={"nav-link " + (curLocation === '' ? "active" : '')} to={settings.base_url }>Inicio</Link>
                  </li>
                  <li onClick={() => staticBody()} className={"nav-item " + (checkActive("nosotros") ? 'active' : '')}>
                    <Link className={"nav-link " + (checkActive("nosotros") ? 'active' : '')} to={ settings.base_url + "nosotros" }>Nosotros</Link>
                  </li>
                  <li onClick={() => staticBody()} className={"nav-item " + (checkActive("ventas") ? 'active' : '')}>
                    <Link className={"nav-link " + (checkActive("ventas") ? 'active' : '')} to={ settings.base_url + "ventas" }>Propiedades en venta</Link>
                  </li>
                  <li onClick={() => staticBody()} className={"nav-item " + (checkActive("alquileres") ? 'active' : '')}>
                    <Link className={"nav-link " + (checkActive("alquileres") ? 'active' : '')} to={ settings.base_url + "alquileres" }>Propiedades en Alquiler</Link>
                  </li>
                  <li onClick={() => staticBody() +  dispatch(updateFiltersDevelopment(6))} className={"nav-item " + (checkActive("listos-para-vivir") ? 'active' : '')}>
                    <Link className={"nav-link " + (checkActive("listos-para-vivir") ? 'active' : '')} to={ settings.base_url + "listos-para-vivir" }>Listos para vivir</Link>
                  </li>
                  <li onClick={() => staticBody() +  dispatch(updateFiltersDevelopment(4))} className={"nav-item " + (checkActive("en-desarrollo") ? 'active' : '')}>
                    <Link className={"nav-link " + (checkActive("en-desarrollo") ? 'active' : '')} to={ settings.base_url + "en-desarrollo" }>En Desarrollo</Link>
                  </li>
                  <li onClick={() => staticBody()} className={"nav-item " + (curLocation === 'novedades' ? "active" : '') + (novelties?.length === 0 ? 'd-none' : '')}>
                    <Link className={"nav-link " + (curLocation === 'novedades' ? "active" : '')} to={ settings.base_url + "novedades" }>Novedades</Link>
                  </li>
                  {/* <li onClick={() => staticBody()} className={"nav-item " + (curLocation === 'contacto' ? "active " : '')}>
                    <Link className={"nav-link fs-11 " + (curLocation === 'contacto' ? "active" : '')} to={ settings.base_url + "contacto" }>Contacto</Link>
                  </li> */}
                </>
              ): // Validar scrolls
                <> 
                  {/* <li className="nav-item">
                    <Link className="nav-link active fs-14" onClick={(e) => scrollTo("proyectos") + e.preventDefault() }>Proyecto</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fs-14" onClick={(e) => scrollTo("ubicación") + e.preventDefault()}>Ubicación</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fs-14" onClick={(e) => scrollTo("contacto") + e.preventDefault()}>Nosotros</Link>
                  </li> */}
                </>
            }
          </ul>
        </nav>
      </div>
    </div>
  )
}

const scrollTo = (section) => {
  var rec = document.getElementById(section).getBoundingClientRect();
  return window.scroll({left:0, top:rec.top + window.scrollY - 200, behavior: 'smooth'});
}
