import Breadcrumbs from "../breadcrumbs"
import { useSelector,useDispatch } from "react-redux";
import trayectoria1 from "../../assets/images/constru-nosotros-1.jpg"
import trayectoria2 from "../../assets/images/constru-nosotros.jpg"
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect, useState} from 'react'
import {get_short_desc} from "../../functions/functions_property";
// import img1 from "../../assets/images/person2.jpg";



const Trayectoria = () => {
    const settings = useSelector(store => store.settings)
    const [textHidden, settextHidden] = useState(true);
    
    

    useEffect(() => {
        AOS.init({ duration : 2000});
    }, []);
    return (
        <>
            <section id="nosotros" className=" div_trayectoria position-relative d-block">
                <div className="container-fluid">
                    <Breadcrumbs   
                        props={[
                            { name: "Inicio", route: settings.base_url, location: "" },
                            { name: "Conocenos", route:  settings.base_url + "nosotros", location: "", active:true },
                            
                        ]}  
                    />
                </div>
               
                <div className="container-fluid fluid-body">
                   <div className="row">
                    <div data-aos="zoom-in-right" className="d-none d-lg-block col-lg-6 div-img-person">      
                    </div>
                    <div data-aos="zoom-in-left" className="col-12 col-lg-6 div-info-person d-flex flex-column">
                    <div className="div-box" >
                            <h2 className="name-person font-0 text-bold">ING. PABLO MASOERO</h2>
                            <a target="_blank" href="https://www.linkedin.com/in/masoero/" className="box-person d-flex">
                                <i className="icon-linkedin-solid me-3"></i>
                                <h3 className="font-0 text-bold">DIRECTOR COMERCIAL</h3>
                            </a>
                        </div>
                    </div>
                   </div>
                </div>
                <div className="container-fluid fluid-parrafo">
                    <div className="row">
                        <div className=" col-12 div-descripcion-trayectoria">
                            <p className="font-1 text-regular fs-7 primer_parrafo">
                            Somos la inmobiliaria oficial de los productos Quartier, focalizada en la venta, reventa y alquiler de los mismos.
                                {/* <p className={(textHidden ? "d-none" : " ")}>
                                A través de nuestros proyectos apuntamos a la mejora del patrimonio urbano y el compromiso social, cumpliendo con todas las obligaciones legales y fiscales, generando empleos formales y aportando a obras comunitarias y de bien público. <br /><br />
                            La marca Quartier – barrio en idioma francés – nace ya con la ambición de generar ámbitos cuasi urbanos pensados con coherencia y simplicidad para ser vividos desde el comienzo según las aspiraciones – quizás ignoradas pero siempre latentes – del target al que van dirigidos.<br /><br />
                                </p> */}
                                <br />
                                <br />
                                Buscamos de esta manera, darle un servicio adicional a nuestros inversores y clientes que han confiado en nosotros.
                            </p>
            
                            {/* <span onClick={() => settextHidden(!textHidden)} className="ver_mas font-0 text-regular fs-9 cursor-pointer">
                                {textHidden ? "Ver Más" : "Ver Menos"}
                            </span> */}
                        </div>
                    </div>
                </div>
               
            </section>
        </>
    
      )
}

export default Trayectoria