import React from 'react'
import { useSelector } from 'react-redux';
import WrapperTopContent from '../wrapperTopContent';
import bgDev from '../../assets/images/foto-nosotros.jpg';

export const FirstSection = () => {

  const data_dev = useSelector((store) => store.developments.developments).data;
  const actions = useSelector((store) => store.actions);
  
  const page = 'nosotros';
  const textReplaceVideo = 'sobre nosotros';

  const actionsInPage = actions?.actions?.filter(action => action?.section.toLowerCase() === page)[0];
  const hasActionsPage = (actionsInPage) ? Boolean(actionsInPage?.section.toLowerCase() === page) : undefined;
  const hasActionsImg = (actionsInPage) ? Boolean(actionsInPage?.small_type === "IEN") : undefined;

  const imgEl = <img className="main__first__section__background__nosotros" src={bgDev} alt="Sobre nosotros"/>;
  const textEl = <div className="main__first__section__title__nosotros d-flex align-items-end justify-content-center">
    <div className="main__first__section__title__container__nosotros  col-12 h-100 d-flex flex-column justify-content-center">
      <h1 className="fs-1 font-0 text-bold">{textReplaceVideo}</h1>
    </div>
  </div>; 
  
  return (
    <section className="main__first__section__nosotros d-flex" id="firstSection">
      <div className="main__first__section__container__nosotros">
        { hasActionsPage
            ? <WrapperTopContent 
                pageProp={page}
                dataProp={data_dev}
                actionsProp={actions}
                videoImgReplaceProp={bgDev} 
                videoTextReplaceProp={textReplaceVideo} /> : null }
        { hasActionsPage && hasActionsImg ? textEl : null }
        { !hasActionsPage && !hasActionsImg ? <> {imgEl} {textEl} </> : null }
      </div>
    </section>
  )
}
