import React from 'react'

// import logoquartier from '../../assets/images/banner-home.png'
import logoquartier from '../../assets/images/logoQuartier.png'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'


export const Sidebar = () => {
  const settings = useSelector(store => store.settings)
  return (
    <>
      <aside className="sidebar d-none d-md-flex">
        <div className='sidebar__vertical__top'>
          <Link to="/" className="brand">
            <img className="brand__image" src={ logoquartier } alt="foto" />
          </Link>
          <div className='text__vertical__top'>
            <p>EXPERIENCIAS ÚNICAS</p>
          </div>
        </div>
        <div className="sidebar__vertical">
          <div className="sidebar__vertical__container d-flex justify-content-center">
            <nav className="vertical__social__network">
              <ul className="vertical__social__container ">
                <li className="vertical__social__item">
                  <a target="_blank" href="https://www.instagram.com/unitequartier/">
                    <i className="icon-instagram-light"></i>
                  </a>
                </li>
                <li>
                  <div className='text__vertical'><a href="https://www.instagram.com/unitequartier/" target="_blank">instagram</a></div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </aside>
    </>
  )
}
