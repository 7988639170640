import React , {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getPropertyAction}  from '../redux/propertiesDucks'
import {cleanDevelopmentAction}  from '../redux/developmentsDucks'
import { getActionsAction } from '../redux/actionsDucks';
import {useParams } from "react-router-dom";
import {get_shortDescription, getGroup_CustomTag} from '../functions/functions_dev'


// -- Componentes -- //
import Main from '../components/propiedad/main'
import Data from '../components/propiedad/data'
import { Header } from '../components/emprendimiento/Header'
import { Whatsapp } from '../components/Whatsapp'
import { SectionForm } from '../components/section-form/SectionForm';
function ScrollToTopOnMount() {

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Resultados(){
    const dispatch = useDispatch();
    const {id} = useParams(); 

    useEffect(() => {
        const getProperty = () => {
            dispatch(getActionsAction())
            dispatch(getPropertyAction(id))
            dispatch(cleanDevelopmentAction())
        }
        getProperty()
    }, [])

    ScrollToTopOnMount()

    const { data } = useSelector((store) => store.properties.property);

    return(
        <div className={"propiedad"} >
            <Whatsapp />
            <Header />
            <Main />
            <Data />
            <SectionForm/>
        </div>
    )
}

export default Resultados
