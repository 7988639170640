import React from 'react'

import { Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { changeLocationAction } from "../redux/themeDucks";
import { updateFields,formTokko } from '../redux/contactDucks'
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useHistory } from "react-router-dom";
import { getWhatsapp } from "../functions/functions_contact";
import $ from 'jquery'

export const Whatsapp = () => {

  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  
  const [disabled, setDisabled] = useState(true);
  const [text,setText] = useState('')
  const [coordenadas, setCoordenadas] = useState(5000);
  const settings = useSelector((store) => store.settings);
  const { data } = useSelector((store) => store.developments.development);
  const unity = useSelector((store) => store.properties.property).data;
  const theme = useSelector((store) => store.theme);
  const curLocation = (useLocation().pathname.split(settings.base_url)[1])

  const [textWpp,setTextWpp] = useState("")

  const [datos, setDatos] = useState({
      email:'',
  })
  const handleInputChange = (event) => {
      setDatos({
          ...datos,
          [event.target.name] : event.target.value, 
      })
  }
  const sendForm = (event) => {
    event.preventDefault()
    dispatch(formTokko('Newsletter'))
    document.getElementById("formContact").reset()
    setText("¡Newsletter enviado correctamente!")
}
  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  useEffect(() => {
      if(curLocation.split("/")[0] !== "desarrollo" &&  curLocation.split("/")[0] !== "unidad"){
        setTextWpp("")
      }
      if(curLocation.split("/")[0] === "unidad"){
        if(unity){
          setTextWpp("Quería consultar sobre el emprendimiento " + unity.development?.name + ", " + unity.real_address)
        }
      }
      else{
        if(data){
          setTextWpp("Quería consultar sobre el emprendimiento " + data.name)
        }
      }
  },[curLocation,data,unity])

  return (
    <div class={"fixed-bar position-fixed "}>
      <a
        href={getWhatsapp(settings.contact.whatsapp.link,textWpp)}
        target="_blank"
        class="icon-whatsapp contact-btn d-flex"
      ></a>
    </div>
  )
}
