import { useEffect, useState } from 'react'
import {useSelector} from 'react-redux'
import { getGroup_CustomTag } from '../../functions/functions_dev'
import zoom from '../../assets/images/signo_mas.svg';
import $ from 'jquery';
import "react-lightbox-pack/dist/index.css";
import { LightBox } from 'react-lightbox-pack'; // <--- Importing LightBox Pack
import SimpleReactLightbox ,{SRLWrapper} from 'simple-react-lightbox'
import { useLightbox } from 'simple-react-lightbox'
  
const Projects = () =>{
    const {data} = useSelector(store => store.developments.development)
    const settings = useSelector(store => store.settings)
    const { openLightbox, closeLightbox } = useLightbox()

	const [toggle, setToggle] =  useState(false);
	const [sIndex, setSIndex] =  useState(0);

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};

  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900
    },
    buttons: {
      iconColor: '#ffff'
    },
    caption: {
      captionColor: '#fff',
      captionFontFamily: 'Raleway, sans-serif',
      captionFontWeight: '300',
      captionTextTransform: 'uppercase'
    },
    progressBar: {
      height: '20px',
      fillColor: '#191919',
      backgroundColor: 'white'
    }
};

const scrollTo = (section) => {
    var rec = document.getElementById(section).getBoundingClientRect();
    return window.scroll({left:0, top:rec.top + window.scrollY - 0, behavior: 'smooth'});
  }

              

    
    return data ? (
        <div class="js-projects-gallery h-100" >
            <section class="section">
                <SRLWrapper>
                    <div className="row no-gutters ">
                        <div className="col-12 col-lg-5 mb-3 mb-lg-0 first_col">
                            <div class="project image-grid-element">
                                <a className="cursor-pointer" title={data.photos[0].description} onClick={() => openLightbox(0)}>
                                    <figure >
                                         <div class="div_zoom"><i className="icon-plus-light"></i></div>
                                         <img alt="" src={data.photos[0].original} />
                                        <figcaption>
                                            <h4 class=" project-category development-category fs-3text-regular">
                                                {data.photos[0].description }
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7 mb-3 mb-lg-0 second_col">
                            <div className="row">
                                <div className='col-12 mb-3 mb-lg-4 first_child_col'>
                                    <div class="project image-grid-element ">
                                        <a className="cursor-pointer" title={data.photos[1].description} onClick={() => openLightbox(1)}>
                                            <figure>
                                                <div class="div_zoom"><i className="icon-plus-light"></i></div>
                                                <img alt="" src={data.photos[1].original} className="w-100" />
                                                <figcaption>
                                                    <h4 class=" project-category development-category fs-3text-regular">
                                                    {data.photos[1].description }
                                                    </h4>
                                                </figcaption>
                                            </figure>
                                        </a>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-6 mb-3 mb-lg-0 second_child_col'>
                                    <div class="project image-grid-element mb-3 mb-lg-4" >
                                        <a className="cursor-pointer" title={data.photos[2].description} onClick={() => openLightbox(2)}>
                                            <figure>
                                            <div class="div_zoom"><i className="icon-plus-light"></i></div>
                                                <img alt="" className="w-100" src={data.photos[2].original} />

                                                <figcaption>
                                                    <h4 class=" project-category development-category fs-3text-regular">
                                                    {data.photos[2].description }
                                                    </h4>
                                                </figcaption>
                                            </figure>
                                        </a>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-6 third_child_col'>
                                    <div class="project image-grid-element mb-5 mb-lg-0 pb-lg-0 mb-3 mb-lg-0">
                                        <a className="cursor-pointer"  title={data.photos[3].description } onClick={() => openLightbox(3)} >
                                        <figure>
                                            <div class="div_zoom"><i className="icon-plus-light"></i></div>
                                            <img alt="" className="w-100" src={data.photos[3].original} />
                                            <figcaption>
                                                <h4 class=" project-category development-category fs-3text-regular">
                                                {data.photos[3].description }
                                                </h4>
                                            </figcaption>
                                            </figure>
                                        </a>
                                    </div>
                                </div>
                            </div>  
                            
                        </div>
                        
                        
                    {/* <LightBox
                        state={toggle}
                        event={lightBoxHandler}
                        data={data.photos}
                        imageWidth="60vw"
                        imageHeight="70vh"
                        thumbnailHeight={50}
                        thumbnailWidth={50}
                        setImageIndex={setSIndex}
                        imageIndex={sIndex}
                    /> */}
                    </div>
                    {data.photos.map((photo, index) => (
                        index > 3 ?
                        <img className='d-none' src={photo.original} alt="" />
                        :''
                    ))}
                </SRLWrapper>
            </section>

            
            
        </div>
    ): 
        <>
        <div class="project image-grid-element"></div>
        <div class="project image-grid-element"></div>
        <div class="project image-grid-element"></div>
        <div class="project image-grid-element"></div>
        </>
}

export default Projects