import React , {useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {cleanDevelopmentAction } from '../redux/developmentsDucks';
import {clearPropertyAction} from '../redux/propertiesDucks'
import { getActionsAction } from '../redux/actionsDucks'

// -- Componentes -- //
import { SectionForm } from '../components/section-form/SectionForm';
import { Header } from '../components/header/Header';
import { Sidebar } from '../components/sidebar/Sidebar';
import { Whatsapp } from '../components/Whatsapp'
import { FirstSection } from '../components/contacto/FirstSection.jsx';
import Main from '../components/contacto/main';


function ScrollToTopOnMount() {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(cleanDevelopmentAction())
      dispatch(clearPropertyAction())
      dispatch(getActionsAction())
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function PageContacto(){

    ScrollToTopOnMount()
    return(
      <div className="contacto">
          <Header />
          <Sidebar />
          <Whatsapp />
          <FirstSection/>
          <Main/> 
          <div className="content">
            <SectionForm />
          </div>
          
      </div>
  )
}

export default PageContacto;
