import React, { useEffect, useState } from 'react'
import { Navbar } from '../navbar/Navbar'
import { MenuMobile } from './MenuMobile'
import {Link} from 'react-router-dom'

import logo from '../../assets/images/logoQuartier.png'
import { getListFilters } from '../../redux/propertiesDucks'
import { useDispatch } from 'react-redux'

export const Header = () => {

  const [isClicked, setIsClicked] = useState(false)
  const [stickyClass, setStickyClass] = useState('');

  const dispatch = useDispatch();
  const handleClick = () => {
    setIsClicked(!isClicked)
    staticBody()
  }

  const staticBody = () => {
    var body = document.body;
    body.classList.toggle("overflow-hidden-mobile");
  }

  useEffect(()=> {
    dispatch(getListFilters())
  },[])

   // nav estatica
  
   useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 190 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };

  // nav estatica


  return (
    <header className={`header ${stickyClass}`} role="header">
      <Navbar />
      <Link to="/">
        <div className="header__menu__mobile__icon d-md-none">
          <img src={ logo } alt="Unité Quartier" />
        </div>
      </Link>
      <div class={`header__button__container d-lg-none ${ isClicked ? 'change' : '' }`} onClick={ handleClick }>
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
      </div>
      <MenuMobile isClicked={ isClicked }/>
    </header>
  )
}
