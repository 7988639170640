import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { Navbar } from "../navbar/Navbar";
import {updateFields,formTokko} from "../../redux/contactDucks";


function Footer() {
  const curLocation = useLocation()
  const location = useSelector((store) => store.theme.location);
  const dispatch = useDispatch();
  const filters = useSelector(store => store.properties.listFilters).data
  const router = useHistory();
  const [offset, setOffset] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [text, setText] = useState("");
  const [coordenadas, setCoordenadas] = useState(5000);
  const settings = useSelector((store) => store.settings);
  const theme = useSelector((store) => store.theme);
  const [open,setOpen] = useState(false)
  
  const [datos, setDatos] = useState({
    email: "",
  });
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };
  const sendForm = (event) => {
    event.preventDefault();
    dispatch(formTokko('Nuevo suscriptor newsletter'))
    document.getElementById("formContact").reset();
    setText("¡Newsletter enviado correctamente!");
  };
  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos));
    };
    update();
  }, [datos]);

  // function get current year
  const currentYear = new Date().getFullYear();

  return (
    <div className="">
      <footer className="text-center row pb-5" style={{margin: "0 auto"}}>
        <div className="col-12 col-lg-4 d-block font-1 justify-content-center align-self-end"> <p className="m-0 p-0">Carlos Spina CUCICBA 7024</p></div>
        {/* <div className="col-12 col-lg-4 d-block">
          <div className="copy mt-auto ">
            Promoted by {" "}<a target="_blank" href="https://mediahaus.com.ar/">{" "}MediaHaus®{" "} </a>{" "} & Powered by <a className="mediacore-link" style={{color:"#ffffff"}} href="https://mediacore.app/">MediaCore®{" "}</a>
          </div>
        </div> */}
        <div className="col-12 col-lg-4 d-block">
          <div className="copy mt-auto d-flex">
            <p> Promoted by {" "}<a className="mediahaus-link"  target="_blank" href="https://mediahaus.com.ar/">{" "}MediaHaus{" "} </a>{" "} - Powered By <a className="mediacore-link"  href="https://mediacore.app/">MediaCore{" "}</a>
            <br /> <span className="reservated">Todos los derechos reservados &reg; {currentYear} </span></p>
            {/* Promoted by {" "}<a className="mediahaus-link"  target="_blank" href="https://mediahaus.com.ar/">{" "}MediaHaus{" "} </a>{" "} - Powered By <a className="mediacore-link"  href="https://mediacore.app/">MediaCore{" "}</a> */}
          </div>
        </div>
        {/* <div className="copy derechos_reservados col-12 col-lg-4 d-flex"><p>Todos los derechos reservados &reg; {currentYear}</p></div> */}
      </footer>
    </div>
  );
}

export default Footer;


