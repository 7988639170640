import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeThemeAction } from "../../redux/themeDucks";
import { redux_updateOrders,getPropertiesAction,clearPropertiesAction } from "../../redux/propertiesDucks";
import propertiesReducer from "../../redux/propertiesDucks";
import { useHistory } from "react-router";
import BreadCrumbs from "../breadcrumbs";
import { useRef } from "react";
import icono from "../../assets/images/logoQuartier.png";
import { Link } from "react-router-dom";

const Main = ({setActiveFilter, activeFilter}) => {
  const router = useHistory()
  const dispatch = useDispatch()
  const [location, setLocation] = useState(window.location.pathname.split("/"));
  const [orders,setOrders] = useState({order:'desc',order_by:'price'})
  const theme = useSelector((store) => store.theme);
  const settings = useSelector(store => store.settings)
  const listed = useSelector(store => store.properties.listed)
  const {data} = useSelector(store => store.properties.properties)
  

  const selectorOrder = useRef()

  useEffect(() => {
    setLocation(window.location.pathname.split("/"));
  }, []);

  // useEffect(() => {
  //   setActiveFilter();
  // }, []);


  // function toggleFilters() {
  //   $("#filter").toggleClass("open");
  //   $(".fixed-bar").toggleClass("d-none")
  //   $("html").toggleClass("overflow-hidden-mobile");
  // }comentado

  function checkView(){
    // get last char to add slash
    const hasSlash = (settings.base_url.slice(settings.base_url.length - 1) !== '/') ? '/' : '';
    if(window.location.pathname.includes(settings.base_url + `${hasSlash}ventas`)){
      return 'Venta'
    }
    if(window.location.pathname.includes(settings.base_url + `${hasSlash}temporarios`)){
      return 'Alquiler temporario'
    }
    return 'Alquiler'
  }

  // function checkView(){
  //   if(window.location.pathname === settings.base_url + 'ventas'){
  //     return 'Venta'
  //   }
  //   return 'Alquiler'
  // }


  function traductorSelect(){
    switch(selectorOrder.current.value){
      case '0': setOrders({order:'desc',order_by:'price'}); break;
      case '1': setOrders({order:'asc',order_by:'price'}); break;
      case '2': setOrders({order:'desc',order_by:'age'}); break;
      case '3': setOrders({order:'asc',order_by:'age'}); break;
      case '4': setOrders({order:'desc',order_by:'roofed_surface'}); break;
      case '5': setOrders({order:'asc',order_by:'roofed_surface'}); break;
    }
  }

  // useEffect(() =>{
  //   functionResizing();
  // }) comentado

  // const functionResizing = () => {
  //   $(document).ready(function() {
  //     $('#resizing_select').change(function(){
  //       $("#width_tmp_option").html($('#resizing_select option:selected').text()); 
  //       $(this).width($("#width_tmp_select").width());  
  //     });
  //   });
  // } comentado

  
  useEffect(() => {
    dispatch(redux_updateOrders(orders))
  },[orders])
  
  // functionResizing() comentado
  
  return  (
    <section className="main-resultados">
      <div className="div-header-icono">
          <Link to="/" className="brand">
            <img className="brand__image" src={icono} alt="foto" />
          </Link>
      </div>
      <div className="main-ventas">
        
        <div className="container-fluid">
          {/* <button className="btn-filters d-block d-lg-none" onClick={() => toggleFilters()}>
            <i className="icon-filter me-3"></i>
            Filtros
          </button> comentado */}
          <BreadCrumbs
                props={[
                  { name: "Inicio", route: settings.base_url, location: "" },
                  {
                    name: "propiedades en " + checkView(),
                    route: settings.base_url + theme.location,
                    location: theme.location,
                    active: true,
                  },
                ]}
              />
          <div className="d-block d-lg-flex justify-content-between align-items-lg-start align-items-end">
            <div>
              <h2 className="title-1 font-0 text-bold fs-1">
                Propiedades en <span className={`regionName ${checkView()}`}> {checkView()} </span>
              </h2>
              <h5 className="subheadline-breadcrum d-none d-lg-block fs-3 text-regular font-0">{data ? data.meta.total_count +  ' resultados totales de búsqueda' : 'Cargando resultados totales...'}</h5>
              <h5 className="subheadline-breadcrum d-block d-lg-none fs-3 text-regular font-0">{data ? data.meta.total_count +  ' resultados totales de búsqueda' : 'Cargando resultados totales...'}</h5>
            </div>
            <div className="div-filtrar">
              <div className="div-filtro">
                {console.log(activeFilter)}
                <button onClick={()=> setActiveFilter(!activeFilter)} className="boton-filtro fs-6 font-0 text-semibold">
                  <i className="icon-filters"></i>
                  Filtrar
                </button>
              </div>
            </div>
            {/* <div className="align-self-end d-flex flex-column align-items-end mb-lg-3" >
              <div>
                <div className="listing-type d-none d-lg-flex">
                  <div>
                    Ver:
                  </div>
                  <i className={`icon-grid ${!listed ? "active"  : ""}`} onClick={()=>{dispatch({type:"CHANGE_TYPE",payload:false})}}></i>
                  <i className={`icon-lateral ${listed ? "active" : ""}`} onClick={()=>{dispatch({type:"CHANGE_TYPE",payload:true})}}></i>
                </div>
              </div>

              <div className="orderby d-flex align-items-end order-mobile text-end  mb-0">
                <div className="d-lg-inline-block d-none order-text"> Ordenar por: </div>
                <div className="select-wrapper">
                  <select ref={selectorOrder} onChange={() => traductorSelect()} name="orderby" id="resizing_select">
                    <option value="0">Mayor precio</option>
                    <option value="1">Menor precio</option>
                    <option value="2">Mayor antigüedad</option>
                    <option value="3">Menor antigüedad</option>
                    <option value="4">Mayor superficie</option>
                    <option value="5">Menor superficie</option>
                  </select>
                  <i className="icon-arrow_down">   </i>
                
                </div>
                <select id="width_tmp_select">
                  <option id="width_tmp_option"></option>
                </select>
              </div>
            </div> */}
          </div>
          { data ?
              data.meta.total_count === 0 ?
              <div className="col-12">
                <span className="not-results-message">En estos momentos no hay resultados para tu búsqueda.</span>
              </div>
              : ''
            :''}
        </div>

      </div>
      
    </section>
  )
};

export default Main;


